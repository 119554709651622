import { Link } from "react-router-dom";
import React from "react";

const Dropdown = ({ submenus }) => {
  return (
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
      {submenus.map((submenu, index) => (
        <li key={index}>
          {submenu?.options?.attributes?.class[0] ? (
            <a
              className="dropdown-item"
              href={`${submenu.relative.replace("backend/", "")}`}
              target={"_blank"}
            >
              {submenu.title}
            </a>
          ) : (
            <Link
              className="dropdown-item"
              reloadDocument
              to={`${submenu.relative.replace("backend/", "")}`}
            >
              {submenu.title}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
