

const Dropdown = ({ submenus }) => {
  
    return (
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            {submenus.map((submenu, index) => (
                <li key={index} >
                     
                       <a className="dropdown-item" aria-expanded="false" href={`${submenu.relative.replace("backend/", "")}`}>{submenu.title}</a>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;


