export default {
    maintainance_mode: `${process.env.REACT_APP_API_URL}/api/v1/maintenance_mode`,
    track_clicks :`${process.env.REACT_APP_API_URL}/api/v1/track-clicks`
  
};


export function _GETConfig(API_KEY) {
     
       return {
        method: 'GET',
        headers: { 
            "api-key": API_KEY && API_KEY ? API_KEY: process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
         }
    };
};

export function _POSTConfig(API_KEY) {
     
    return {
     method: 'POST',
     headers: { 
         "api-key": API_KEY && API_KEY ? API_KEY: process.env.REACT_APP_API_KEY,
         'Content-Type': 'application/json'
      }
 };
};

export function getAuthGETConfig(authToken) {
    return {
        method: 'GET',
        headers: {
            TCRAUTH: authToken,
            'Content-Type': 'application/json',
             
        }
    };
}