import Dropdown from './Dropdown'
import { Link } from 'react-router-dom'

const MenuItem = ({ items }) => {
    const test = items.relative;
    const url = typeof test === 'string' ? test.replace("/backend", "") : '';
    return (
        <>
        <li className="nav-item dropdown d-block d-sm-block d-md-block d-lg-none">
            {items.below ? (
                <>
                       <Link className="nav-link dropdown-toggle "  aria-expanded="false"  to={url} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true">{items.title}</Link> 
                     {/* <Link className="nav-link dropdown-toggle" to={`/${items.alias}`} id="navbarDropdown" role="button"  >{items.title}</Link> */}
                    <Dropdown submenus={items.below} />
                </>
            ) : (
                 <Link className="nav-link "  aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>
                 //<Link className="nav-link dropdown-toggle" to={`/${items.alias}`} id="navbarDropdown" role="button"  >{items.title}</Link>
            )}
        </li>
        {/* web view */}
        <li className="nav-item dropdown d-none d-md-none d-lg-block ">
            {items.below ? (
                <>
                       <Link className="nav-link dropdown-toggle "  aria-expanded="false"  to={url} style={{pointerEvents:"none"}} id="navbarDropdown" role="button" >{items.title}</Link> 
                     {/* <Link className="nav-link dropdown-toggle" to={`/${items.alias}`} id="navbarDropdown" role="button"  >{items.title}</Link> */}
                    <Dropdown submenus={items.below} />
                </>
            ) : (
                 <Link className="nav-link dropdown-toggle"  aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>
                 //<Link className="nav-link dropdown-toggle" to={`/${items.alias}`} id="navbarDropdown" role="button"  >{items.title}</Link>
            )}
        </li>
   </> );
};

export default MenuItem;

