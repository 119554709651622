import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../pages/HomePage/Loader";

function Footer() {
  const [visible, setVisible] = useState(false);

  const [footerMenuItemsData, setFooterMenuItemsData] = useState();
  const [loader, setloader] = useState("");
  const [footerActive, setfooterActive] = useState(false);

  const toggleVisible = () => {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  useEffect(() => {
    let API = `${process.env.REACT_APP_API_URL}/api/menu_items/footer?format=json`;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const fetchApiData = async (url) => {
      setloader(true);
      try {
        const res = await fetch(url, {
          headers: {
            "api-key": API_KEY,
          },
        });
        const data = await res.json();
        setFooterMenuItemsData(data);
        setfooterActive(true);
        setloader(false);
      } catch (error) {
        console.log(error.response);
      }
    };
    fetchApiData(API);
    window.addEventListener("scroll", toggleVisible);
  }, []);
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* Desktop Footer*/}
      {footerActive ? (
        <>
          <div className="footer d-lg-block d-md-none">
            <div className="container-fluid">
              <div className="container-main">
                <div className="row">
                  <div className="col-md-4">
                    <div className="footer-1">
                      <img
                        src={
                          process.env.REACT_APP_DOMAIN_BASE_URL + "/files/logos/DSCI-White-1.svg"
                        }
                        alt="logo"
                        style={{ height: "75px" }}
                      />

                      <p>
                        <span>Operational Office</span> – 4th Floor, NASSCOM Campus,
                        Plot No. 7-10, Sector 126, Noida, UP -201303
                      </p>
                      <div className="social-icon">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/dsci.connect"
                              target="_blank"
                              className="float"
                            >
                              {/* <i className="fab fa-facebook-f"></i> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/DSCI_Connect"
                              target="_blank"
                              className="float"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-twitter-x"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                              </svg>
                              {/* <i className="fab fa-twitter"></i> */}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/dsci.connect/"
                              target="_blank"
                              className="float"
                            >
                              {/* <i className="fab fa-instagram"></i> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-instagram"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/data-security-council-of-india"
                              target="_blank"
                              className="float"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                              {/* <i className="fab fa-linkedin-in"></i> */}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/user/dscivideo"
                              target="_blank"
                              className="float"
                            >
                              {/* <i className="fab fa-youtube"></i> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="35"
                                fill="currentColor"
                                className="bi bi-youtube"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://open.spotify.com/show/2y9TobEzcOYApfXIZKqEJR?si=f0d84ffb30e64714"
                              target="_blank"
                              className="float"
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="-33.4974 -55.829 290.3108 334.974"><path d="M177.707 98.987c-35.992-21.375-95.36-23.34-129.719-12.912-5.519 1.674-11.353-1.44-13.024-6.958-1.672-5.521 1.439-11.352 6.96-13.029 39.443-11.972 105.008-9.66 146.443 14.936 4.964 2.947 6.59 9.356 3.649 14.31-2.944 4.963-9.359 6.6-14.31 3.653m-1.178 31.658c-2.525 4.098-7.883 5.383-11.975 2.867-30.005-18.444-75.762-23.788-111.262-13.012-4.603 1.39-9.466-1.204-10.864-5.8a8.717 8.717 0 015.805-10.856c40.553-12.307 90.968-6.347 125.432 14.833 4.092 2.52 5.38 7.88 2.864 11.968m-13.663 30.404a6.954 6.954 0 01-9.569 2.316c-26.22-16.025-59.223-19.644-98.09-10.766a6.955 6.955 0 01-8.331-5.232 6.95 6.95 0 015.233-8.334c42.533-9.722 79.017-5.538 108.448 12.446a6.96 6.96 0 012.31 9.57M111.656 0C49.992 0 0 49.99 0 111.656c0 61.672 49.992 111.66 111.657 111.66 61.668 0 111.659-49.988 111.659-111.66C223.316 49.991 173.326 0 111.657 0" fill="#ffffff"></path></svg>
                          </a>
                          </li>
                        </ul>
                      </div>
                      <p>
                        <span>In Association</span>
                      </p>
                      <a href="https://www.nasscom.in/" target="_blank">
                        <img
                          className="af-logo"
                          src={
                            process.env.REACT_APP_DOMAIN_BASE_URL +
                            "/files/logos/nasscom.svg"
                          }
                        />
                      </a>

                      <a href="https://nasscomfoundation.org/" target="_blank">
                        <img
                          className="af-logo"
                          src={
                            process.env.REACT_APP_DOMAIN_BASE_URL +
                            "/files/logos/nasscom-foundation.svg"
                          }
                        />
                      </a>
                    </div>
                  </div>
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {footerMenuItemsData?.map((footer_data, index) => {
                        return (
                          <div className="col-md-2">
                            <div className="footer-2">
                              <h3>{footer_data?.title}</h3>
                              <ul>
                                {footer_data?.below?.map((below_data, index1) => {
                                  return (
                                    <li>
                                      <a
                                        href={`${below_data?.relative.replace(
                                          "backend/",
                                          ""
                                        )}`}
                                        target={
                                          below_data?.options?.attributes?.target
                                        }
                                      >
                                        {below_data?.title}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="row footer-3 d-flex">
                  <div className="col-md-6 col-lg-6">
                    <Link to="/content/terms-service" reloadDocument>
                      Terms of Service
                    </Link>{" "}
                    <span>|</span>{" "}
                    <Link to="/content/privacy-policy" reloadDocument>
                      Privacy Policy
                    </Link>{" "}
                    <span>|</span>{" "}
                    <Link to="/content/disclaimer" reloadDocument>
                      Disclaimer{" "}
                    </Link>
                    <br />
                    <Link
                      to="/content/vulnerability-disclosure-program"
                      reloadDocument
                    >
                      Vulnerability Disclosure Program
                    </Link>
                    <span>|</span>
                    <Link to="/content/sitemap" reloadDocument>
                      Sitemap
                    </Link>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <p className="float-end">
                      © {new Date().getFullYear()} DSCI. All Rights Reserved{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Mobile Footer*/}

          <div className="footer mobile-footer d-lg-none d-md-block">
            <div className="container-fluid">
              <div className="container-main">
                <div className="row"></div>
                <div className="col-12 ">
                  <div className="footer-1 text-center w-100">
                    <p className="w-100">
                      <span>Operational Office</span> – 4th Floor, NASSCOM Campus,
                      Plot No. 7-10, Sector 126, Noida, UP -201303
                    </p>
                    <div className="social-icon">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/dsci.connect"
                            target="_blank"
                            className="float"
                          >
                            {/* <i className="fab fa-facebook-f"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-facebook"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/DSCI_Connect"
                            target="_blank"
                            className="float"
                          >
                            {/* <i className="fab fa-twitter"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-twitter-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/dsci.connect/"
                            target="_blank"
                            className="float"
                          >
                            {/* <i className="fab fa-instagram"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-instagram"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/data-security-council-of-india"
                            target="_blank"
                            className="float"
                          >
                            {/* <i className="fab fa-linkedin-in"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/user/dscivideo"
                            target="_blank"
                            className="float"
                          >
                            {/* <i className="fab fa-youtube"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="35"
                              fill="currentColor"
                              className="bi bi-youtube"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                            </svg>
                          </a>
                        </li>
                        <li>
                            <a
                              href="https://open.spotify.com/show/2y9TobEzcOYApfXIZKqEJR?si=f0d84ffb30e64714"
                              target="_blank"
                              className="float"
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="-33.4974 -55.829 290.3108 334.974"><path d="M177.707 98.987c-35.992-21.375-95.36-23.34-129.719-12.912-5.519 1.674-11.353-1.44-13.024-6.958-1.672-5.521 1.439-11.352 6.96-13.029 39.443-11.972 105.008-9.66 146.443 14.936 4.964 2.947 6.59 9.356 3.649 14.31-2.944 4.963-9.359 6.6-14.31 3.653m-1.178 31.658c-2.525 4.098-7.883 5.383-11.975 2.867-30.005-18.444-75.762-23.788-111.262-13.012-4.603 1.39-9.466-1.204-10.864-5.8a8.717 8.717 0 015.805-10.856c40.553-12.307 90.968-6.347 125.432 14.833 4.092 2.52 5.38 7.88 2.864 11.968m-13.663 30.404a6.954 6.954 0 01-9.569 2.316c-26.22-16.025-59.223-19.644-98.09-10.766a6.955 6.955 0 01-8.331-5.232 6.95 6.95 0 015.233-8.334c42.533-9.722 79.017-5.538 108.448 12.446a6.96 6.96 0 012.31 9.57M111.656 0C49.992 0 0 49.99 0 111.656c0 61.672 49.992 111.66 111.657 111.66 61.668 0 111.659-49.988 111.659-111.66C223.316 49.991 173.326 0 111.657 0" fill="#ffffff"></path></svg>
                          </a>
                          </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row footer-3 text-center">
                  <div className="col-12">
                    <a href="/content/terms-service" reloadDocument>
                      Terms of Service
                    </a>{" "}
                    <span>|</span>
                    <a href="/content/privacy-policy" reloadDocument>
                      Privacy Policy
                    </a>
                    <span>|</span>{" "}
                    <a href="/content/sitemap" reloadDocument>
                      Sitemap
                    </a>
                  </div>
                  <div className="col-12">
                    <p className="text-center">
                      © {new Date().getFullYear()} DSCI. All Rights Reserved{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            id="scrollTop"
            style={{ display: visible ? "block" : "none" }}
            onClick={handleScroll}
          >
            <i className="fas fa-angle-up"></i>{" "}
          </button>
        </>
      ) : ''}
    </>
  );
}

export default Footer;
