import React from 'react'

function Loader() {
  
  return (
   <>
   <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
   </>
  )
}

export default Loader
